import React, {useEffect, useState} from "react"
import {Container,Navbar,Nav} from 'react-bootstrap';
import { Link } from "gatsby"
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-input-range/lib/css/index.css'

import SEO from "../../components/seo"

import Header from  "../../components/Common/Header"; 
import NavLink from  "../../components/NavLink/Custom_Presentation"; 
import {config} from '../../components/Common/constant';

import DigitalDomination from '../../components/modules/digital_domination';
import CampaignReport from '../../components/modules/campaign_report';

import { useContainerContext } from "../../store/ContainerContext";
import { GET_ARTICLE } from "../../queries/common_use_query";

import { isBrowser} from "../../services/auth"

const PresentationPage = () => {
    const context = useContainerContext();
    const { property_data } = context.state;

    const [ article_data, setArticleData ] = useState('');
    const { loading, error, data } = GET_ARTICLE('digital-domination-for-your-home');

    useEffect(()=>{
        setArticleData(data && data.articles[0]);
    }, [data]);

    return <>
        <SEO title={article_data && article_data.Meta_Title} description={article_data && article_data.Meta_Description} />
        <div className="pre-wrapper">

        <Header />

        <div className="section-wrapper custom_present_inner pt-0">
            <DigitalDomination presentation="custom" />

            {(isBrowser() && property_data) && <CampaignReport property_data={property_data} /> }

            
        </div>

        <NavLink tab_name="Marketing" prevLink={config.custom_launch}  nextLink={config.custom_prop_datetime} />

        </div>
    </>
}

export default PresentationPage